import Apple from 'assets/landing-page/apple-white.svg'
import GooglePlay from 'assets/landing-page/googleplay.svg'
import { Button } from 'common/components/Button/Button'
import Link from 'next/link'
import { FC } from 'react'
import styled from 'styled-components'

interface IDownloadApp {
  text: string
  size?: 'small' | 'large'
  color?: string
}

const DownloadAppHero: FC<IDownloadApp> = ({ text, size = 'large', color = '#242424' }) => {
  return (
    <Div size={size} color={color}>
      <p className="download-app w-full">{text}</p>
      <div className="buttons-div mt-3 md:mt-0 flex flex-column md:flex-row">
        <Link href="https://play.google.com/store/apps/details?id=com.seaplify" passHref legacyBehavior>
          <a target="_blank">
            <Button variant="black" className="redirect-button google-play w-full gap-2">
              <GooglePlay style={{ width: '25px', height: '27.1px' }} /> Google Play
            </Button>
          </a>
        </Link>
        <Link href="https://apps.apple.com/ro/app/seaplify-maritime-partner/id6463020570" passHref legacyBehavior>
          <a target="_blank">
            <Button variant="black" className="redirect-button apple-store w-full gap-2">
              <Apple style={{ width: '31.5px', height: '37.8px' }} /> App Store
            </Button>
          </a>
        </Link>
      </div>
    </Div>
  )
}

export default DownloadAppHero

const Div = styled.div<{ size?: 'small' | 'large'; color: string }>`
  .download-app {
    font-weight: 700;
    color: ${({ color }) => color};
    font-size: 24px;
    margin-bottom: 1rem;
  }

  .buttons-div {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
  }

  .redirect-button {
    font-weight: 500;

    @media screen and (min-width: 576px) {
      max-width: 214px;
    }

    @media screen and (max-width: 576px) {
      font-size: 22px;
      line-height: 22px;
      padding: 3px 3px;
      width: 167px;
      height: 50px;
    }

    @media screen and (max-width: 400px) {
      width: 140px;
    }

    @media screen and (max-width: 340px) {
      width: 120px;
    }

    ${({ size }) =>
      ({
        small: 'font-size: 12px; line-height: 20px; padding: 4px 8px; width: 115px; height: 30px',
        large: 'font-size: 21px; line-height: 28px; padding: 8px 16px; width: 205px; height: 59px',
      }[size!])};
  }
`
